<template>
  <div class="container">
    <div class="my-5 bg-white shadow-sm rounded-3 p-4">
      <div class="number-of-claims row">
        <div class="col-md-2"></div>
        <span
          class="text-start fw-normal fs-4 col-6 col-xs-6 col-md-5 col-lg-4"
        >
          Total number of claims:
          <strong>{{ numberOfClaims }}</strong>
        </span>
        <div class="col pt-1">
          <button
            class="btn text-success btn-sm mx-2 bg-gray-600 border-0"
            type="button"
            :disabled="numberOfClaims >= 1"
            @click="addClaims"
          >
            <img src="@/assets/svg/plus.svg" class="icon-white" alt="add" />
          </button>
          <button
            class="btn btn-sm text-danger bg-gray-600 border-0"
            type="button"
            :disabled="numberOfClaims <= 1"
            @click="minusClaims"
          >
            <img src="@/assets/svg/minus.svg" class="icon-white" alt="delete" />
          </button>
        </div>
      </div>
    </div>
    <div class="d-flex min-h-100 my-2 row">
      <div class="col-8">
        <h1 class="text-center">Body Part</h1>
        <p class="text-gray-500 pb-5 text-center">
          Please select one or more body parts to start the assessment process.
        </p>

        <div class="container-fluid position-relative">
          <!-- right dropdown selected part -->
          <div
            v-if="allowRightDropdown"
            class="position-absolute additional-select-form-right"
            style="z-index: 99"
          >
            <button
              id="rightHandDropdown"
              class="btn px-3 btn-dark text-white dropdown-toggle"
              data-bs-toggle="dropdown"
              data-bs-offset="0,10"
              data-bs-auto-close="outside"
              aria-expanded="false"
            >
              {{ selectedParent }}
            </button>
            <div class="dropdown-menu px-3" aria-labelledby="rightHandDropdown">
              <div>
                <label
                  v-for="(parts, index) in childPartsOptions"
                  :key="index"
                  :for="parts"
                  class="text-nowrap my-2"
                >
                  <input
                    :id="parts"
                    v-model="selectedParts"
                    :value="{
                      title: parts,
                      apiName: parts.replace(/Right /g, ''),
                    }"
                    class="form-check-input me-2"
                    :aria-label="parts"
                    type="checkbox"
                  />

                  {{ parts }}
                </label>
              </div>
            </div>
          </div>
          <BaseHuman ref="humanBody" v-model="selected" />

          <!-- left dropdown selected part -->
          <div
            v-if="allowLeftDropdown"
            class="position-absolute additional-select-form-left"
            style="z-index: 99"
          >
            <button
              id="leftHandDropdown"
              class="btn px-3 btn-dark text-white dropdown-toggle"
              data-bs-toggle="dropdown"
              data-bs-offset="0,10"
              data-bs-auto-close="outside"
              aria-expanded="false"
            >
              {{ selectedParent }}
            </button>
            <div class="dropdown-menu px-3" aria-labelledby="leftHandDropdown">
              <div>
                <label
                  v-for="(parts, index) in childPartsOptions"
                  :key="index"
                  :for="parts"
                  class="text-nowrap my-2"
                >
                  <input
                    :id="parts"
                    v-model="selectedParts"
                    :value="{
                      title: parts,
                      apiName: parts.replace(/Left /g, ''),
                    }"
                    class="form-check-input me-2"
                    :aria-label="parts"
                    type="checkbox"
                  />

                  {{ parts }}
                </label>
              </div>
            </div>
          </div>
        </div>
      </div>

      <!-- display selected body parts -->

      <div class="h-50 col-4 mt-5">
        <div
          class="d-flex flex-column align-item-start justify-content-start py-5 pe-5"
        >
          <h2 class="text-start pb-4 fw-normal fs-4">Body Part Selected:</h2>

          <div class="text-start">
            <div class="d-flex flex-wrap">
              <div
                v-for="(item, index) in finalSelections"
                :id="index"
                :key="item"
                class="bg-primary text-white py-2 px-3 me-2 rounded-3 mb-2 align-items-center d-flex"
              >
                {{ item.title }}
                <button
                  class="ps-1 text-white ms-2 bg-transparent btn p-0 m-0"
                  @click="delSelection(index)"
                >
                  x
                </button>
              </div>
            </div>
          </div>
          <button
            class="btn btn-success mt-5 p-2 text-white w-50"
            :disabled="finalSelections.length > 0 ? false : true"
            @click="readyForAssessment"
          >
            Create Assessment
          </button>
        </div>
        <div class="estimated-cost">
          <EstimatedCost :cost="estimatedCost" />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import BaseHuman from '@/components/Base/BaseHumanSVG.vue'
import EstimatedCost from '@/components/ImpairEx/EstimatedCost.vue'

// body part now have tooltip-PTSD triggers

export default {
  name: 'BodyPartSelection',
  components: { BaseHuman, EstimatedCost },

  data() {
    return {
      allowLeftDropdown: false,
      allowRightDropdown: false,
      additionalOptionsParts: [
        'Right Arm',
        'Left Arm',
        'Right Hand',
        'Left Hand',
        'Left Leg',
        'Right Leg',
        'Torso',
      ],
      optionArm: ['Upper Arm', 'Forearm'],
      optionLeg: ['Upper Leg', 'Knee', 'Lower Leg', 'Ankle'],
      optionLHand: [
        'Left Wrist',
        'Left Thumb',
        'Left Index Finger',
        'Left Middle Finger',
        'Left Ring Finger',
        'Left Pinky Finger',
      ],
      optionRHand: [
        'Right Wrist',
        'Right Thumb',
        'Right Index Finger',
        'Right Middle Finger',
        'Right Ring Finger',
        'Right Pinky Finger',
      ],
      optionTorso: [
        'Cervical Spine',
        'Thoracic Spine',
        'Lumbar Spine',
        'Chest',
        'Abdomen',
      ],
      selectedParent: '',
      listOfBodyPart: [],

      selectedParts: [],
      selected: '',
      currentSide: null,

      numberOfClaims: 1,
    }
  },

  computed: {
    childPartsOptions() {
      if (this.selectedParent.includes('Arm')) {
        return this.optionArm
      } else if (this.selectedParent.includes('Leg')) {
        return this.optionLeg
      } else if (this.selectedParent.includes('Hand')) {
        if (this.selectedParent.includes('Left')) {
          return this.optionLHand
        } else return this.optionRHand
      } else if (this.selectedParent.includes('Torso')) {
        return this.optionTorso
      } else return false
    },

    finalSelections() {
      if (this.selected) {
        this.selectPart(this.selected)
      }
      return this.selectedParts
    },

    estimatedCost() {
      if (this.selectedParts.length) {
        return 'X.00'
      }
      return 0
    },
  },

  async mounted() {
    await this.getBodyPartList()
  },

  methods: {
    addClaims() {
      if (this.numberOfClaims >= 1) {
        this.numberOfClaims++
      }
    },

    minusClaims() {
      if (this.numberOfClaims > 1) {
        this.numberOfClaims--
      }
    },

    selectPart(bodyPart) {
      // reset
      this.allowRightDropdown = false
      this.allowLeftDropdown = false

      // if the part allows more option, show it
      if (this.additionalOptionsParts.find((x) => x == bodyPart)) {
        this.selectedParent = bodyPart

        if (bodyPart.includes('Right')) {
          this.allowRightDropdown = true
        }

        if (bodyPart.includes('Left') || bodyPart.includes('Torso')) {
          this.allowLeftDropdown = true
        }

        this.selected = ''
      } else {
        this.addToAry({
          title: bodyPart,
          apiName: bodyPart.replace(/\s|Left|Right/g, ''),
        })
      }
    },
    addToAry(item) {
      // when click again, able to remove selected parts
      let elementIndexAry = this.selectedParts
        .map((e) => e.title)
        .indexOf(item.title)

      if (elementIndexAry === -1) {
        this.selectedParts.push(item)
        this.selected = ''
      } else {
        this.selectedParts.splice(elementIndexAry, 1)
        this.selected = ''
      }
    },

    delSelection(idx) {
      this.selectedParts.splice(idx, 1)
    },

    selectedBodyPartAryForApiCall() {
      let finalAry = []

      //  obj to filter example {title: 'Left Shoulder', apiName: 'Shoulder'}
      // and api returns {
      //   "id": 1,
      //   "name": "elbow",
      //   "has_directions": true
      // },

      if (this.listOfBodyPart.length > 0) {
        let filteredApiOnlyAry = this.finalSelections.map((x) => {
          //have to reserve the sentence for fingers to fit api calls
          if (
            x.apiName.toLowerCase().includes('finger') ||
            x.apiName.toLowerCase().includes('thumb')
          ) {
            return {
              display_name: x.apiName,

              directions: [x.title.substring(0, 1)],
            }
          } else if (x.apiName.toLowerCase().includes('spine')) {
            return {
              display_name: x.apiName,
              directions: [],
            }
          } else
            return {
              display_name: x.apiName,
              directions: [x.title.substring(0, 1)],
            }
        })

        // combine L and R if detected both
        let filteredDirectionAry = []

        filteredApiOnlyAry.forEach((part) => {
          let foundIndex = filteredDirectionAry.findIndex(
            (e) => e.display_name === part.display_name
          )

          if (foundIndex !== -1) {
            filteredDirectionAry[foundIndex].directions = filteredDirectionAry[
              foundIndex
            ].directions.concat(part.directions)
          } else filteredDirectionAry.push(part)
        })

        let result = []
        filteredDirectionAry.forEach(({ display_name, directions }) => {
          let found = this.listOfBodyPart.find(
            (e) => e.display_name == display_name
          )

          if (found) {
            result.push({
              body_part: found.id,
              directions,
            })
          }
        })

        return (finalAry = result)
      }

      return finalAry
    },

    getBodyPartList() {
      this.$store
        .dispatch('getBodyPartsList')
        .then((response) => {
          let data = response.data

          this.listOfBodyPart = data
        })
        .catch((error) => {
          console.log(error)
        })
    },

    // // pew pew bring me to assessment page when fired!
    readyForAssessment() {
      // temporary allow 1 assessment at a time

      let result = this.selectedBodyPartAryForApiCall()

      this.$store.commit('STORE_BODYPARTS', result)

      this.$store
        .dispatch('postNewAssessment', result)
        .then((response) => {
          let data = response.data

          // Trigger toast text
          this.$root.toasts.push({
            status: 'success',
            content: `Successfully created referral! Doctors and nurses may see it on RTWSA List`,
          })

          this.$store.commit('STORE_BODYPARTS', data)

          this.$store.commit('STORE_ASSESSMENTDATA', data)

          this.$store.commit('STORE_ASSESSMENTID', parseInt(data.id))

          this.$router.push({
            name: 'lawyer-referral-creation',
            params: { id: 'new' },
          })
        })
        .catch((error) => {
          console.log(error)

          // Trigger toast text
          this.$root.toasts.push({
            status: 'danger',
            content: `Ops something went wrong. ${error.response}`,
          })
        })
    },
  },
}
</script>

<style lang="scss" scoped>
.additional-select-form {
  &-left {
    max-width: 200px;
    top: 30%;
    left: 70%;
  }

  &-right {
    max-width: 200px;
    top: 30%;
    left: 5%;
  }
}

.estimated-cost {
  max-height: 500px;

  display: flex;
  align-items: top;
  padding: 30px;
  background: #ffffff;
  box-shadow: 0px 4px 20px rgba(0, 0, 0, 0.1);
  border-radius: 10px;
}

.icon-white {
  filter: brightness(0) invert(1);
}

ul {
  padding: 0;
  li {
    font-size: 16px;
  }
}
</style>
